@mixin fadeInOpacity() {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.25s;
}

@mixin borderBottomHover($size: 7px) {
  &::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: -16px;
    left: 0;
    height: $size;
    border-radius: $size;
    background-color: $orange;
    content: '';
    overflow: hidden;
  }
}

@mixin coverBackground {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@mixin sectionStyle {
  @include sectionPadding;
  max-width: $sectionWidth;
  margin: 0 auto;
}

@mixin sectionPadding {
  padding: 85px 0 100px;

  @include tablet {
    padding: 70px 0;
  }

  @include mobile {
    padding: 50px 0;
  }
}

@mixin col4 {
  position: relative;
  width: 100%;
  max-width: 50%;
  margin-bottom: $margin;
  padding-right: calc($margin / 2);
  padding-left: calc($margin / 2);

  flex: 0 0 50%;

  @include small-phone {
    max-width: 100%;
    flex: 0 0 100%;
  }

  @include desktop {
    max-width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }
}

@mixin col3 {
  @include col4;

  @include desktop {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@mixin col6 {
  @include col4;
  max-width: 33.3333333333%;
  flex: 0 0 33.3333333333%;

  @include small-phone {
    max-width: 100%;
    flex: 0 0 100%;
  }

  @include desktop {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

// buttons
@mixin linkStyle {
  display: inline-flex;
  height: $buttonHeight;
  padding: 0;
  border: none;
  background-color: transparent;
  color: $orange;
  font-size: $fontSizeH4;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: $buttonHeight;
  align-items: center;
  justify-content: flex-start;

  > svg {
    height: 14px;
    margin: 0 0 0 10px;

    path {
      fill: $orange;
      stroke: $orange;
    }
  }
}

@mixin buttonStyle {
  height: $buttonHeight;
  padding-right: $margin;
  padding-left: $margin;
  border: 2px solid $orange;
  border-radius: calc($buttonHeight / 2);
  background-color: white;
  line-height: $buttonHeight - 4px;

  justify-content: center;

  @content;

  > svg {
    margin: calc(($buttonHeight - 25px - 2px) / 2) 10px calc(($buttonHeight - 25px - 2px) / 2) 0;
  }
}

@mixin imageShadow {
  box-shadow: 20px 20px $purple;

  @include lt-medium {
    box-shadow: 10px 10px $purple;
  }
}

@mixin styleH1 {
  font-size: $fontSizeH1; // 36px
  letter-spacing: 1px;

  @include tablet {
    font-size: 2.14rem;
  }

  @include mobile {
    margin: 0 0 $margin;
    font-size: $fontSizeH1Mobile; // 24px
    letter-spacing: 0.5px;
  }
}

@mixin styleH2 {
  font-size: $fontSizeH2; // 30px
  letter-spacing: 0.5px;

  @include tablet {
    font-size: 1.85rem; // 26px
  }

  @include mobile {
    font-size: $fontSizeH2Mobile;
    line-height: 1.33;
  }
}

@mixin styleH3 {
  font-size: $fontSizeH3; // 24px
  letter-spacing: 0.5px;
  line-height: 1.5;

  @include mobile {
    font-size: $fontSizeH3Mobile; // 16px
    letter-spacing: 0.55px;
  }
}

@mixin styleH4 {
  font-size: $fontSizeH4; // 18px
  letter-spacing: 0.5px;
  line-height: 1.333;

  @include mobile {
    font-size: 1.15rem;
  }
}

@mixin styleH5 {
  font-size: $fontSizeH5; // 16px
  letter-spacing: 0.5px;
  line-height: 1.5;

  @include mobile {
    font-size: $fontSizeH5Mobile; // 14px
    letter-spacing: 0;
  }
}

@mixin sectionHeading {
  display: block;
  margin: 0 0 25px;
  color: $blueDeep;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 25px;
  text-transform: uppercase;

  @include mobile {
    margin: 0 0 calc($margin / 4);
    font-size: $fontSizeSmall;
    line-height: 2;

    svg {
      height: 18px;
      margin-right: 5px;
    }
  }

  &.whiteText {
    color: $whitePlain;
  }

  svg {
    width: auto;
    height: 25px;
    margin-right: 10px;

    vertical-align: middle;
  }
}

@mixin sectionTitle {
  margin: 0 0 35px;
  font-size: $fontSizeH1;
  letter-spacing: 1px;
  line-height: 1.35;
  text-align: left;

  @include mobile {
    margin: 0 0 $margin;
    font-size: 1.72rem;
    letter-spacing: 0.5px;
    line-height: 1.5;
  }
}

@mixin cardHeading {
  @include sectionHeading;
  margin: 0;

  @include mobile {
    margin: 0;
  }
}

@mixin textGrey {
  margin: 0 0 25px;
  color: $grey;

  @include mobile {
    margin: 0 0 20px;
  }
}

@mixin textGreySmall {
  color: $grey;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.71;
}

@mixin textContent {
  font-size: $fontSizeH5;
  letter-spacing: 0.44px;
}

@mixin blockTextAbstract {
  color: $headingColor;
  font-size: 1.72rem;
  letter-spacing: 0.67px;
  line-height: 1.5;
  margin: 0 0 25px;

  @include mobile {
    font-size: 1.28rem;
    letter-spacing: 0.5px;
    margin: 0 0 20px;
  }
}

@mixin widthLimit {
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
}

@mixin heroHeadline {
  @include blockTextAbstract;
  color: $blue;
  margin: 0 0 1rem;
  max-width: 550px;
  white-space: pre-wrap;

  @include lt-large-desktop {
    max-width: unset;
  }
}

@mixin textSub {
  color: $grey;
  font-size: 1rem;
  letter-spacing: 0.44px;
  line-height: 1.4;
}

@mixin serviceLabel {
  margin: 0;
  color: $purple;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.28;
  text-align: center;
}

// footer
@mixin footerHeadText {
  color: $footerHeadColor;
  font-size: $fontSizeH5;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
}

@mixin footerCopyright {
  color: $colorPrimaryBlueBrand;
  font-size: $fontSizeSmall;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
}

//////////////////
// services block
//////////////////
@mixin serviceBlock {
  position: relative;
  padding: 30px 33px 27px;

  @include tablet {
    padding: 30px 22px 27px;
  }

  @include medium {
    border-radius: 12px;
  }

  @include lt-medium {
    margin-right: -(calc($margin / 2));
    margin-left: -(calc($margin / 2));
    padding: 30px 15px 20px;
  }

  p {
    @include styleH4;
  }

  > p > a {
    padding: 0;
    font-size: 1.28rem;
    font-weight: bold;
    letter-spacing: 0.46px;

    @include mobile {
      font-size: 1.14rem;
      letter-spacing: 0.5px;
    }

    svg {
      margin: 0 0 0 17px;
    }
  }
}

@mixin serviceBlockStyle($color, $background) {
  background-color: $background;

  h3,
  h5,
  p,
  > a,
  > p > a {
    color: $color!important;

    > img {
      margin: 0 0 0 17px;
    }

    > svg g path {
      fill: $color;
      stroke: $color;
    }
  }

  > p > a {
    background-size: 54px 14px;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 70px;
    text-decoration: none!important;
  }
}

@mixin TextHeadingLarge36 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 141.4%;
}

@mixin TextHeadingLarge30 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 123.6%;
}

@mixin TextHeadingMedium24 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 123.6%;
}

@mixin TextHeadingMedium21 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 123.6%;
}

@mixin TextHeadingSmall18 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 123.4%;
}

@mixin TextHeadingSmall16 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 141.4%;
}

@mixin TextHeadingSmall12 {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 141.4%;
}

@mixin TextBodyMedium20 {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 141.4%;
}

@mixin TextBodySmall18 {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 141.4%;
}

@mixin TextBodySmall16 {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 141.4%;
}

@mixin TextBodySmall12 {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 141.4%;
}
