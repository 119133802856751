@import "~styles/globals.scss";
.row {
  @include mobile {
    &:not(.dontTile) {
      background-image: none !important; // stylelint-disable-line declaration-no-important
    }

    &.dontTile {
      background-position: top 0 left 75vw !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.right {
    text-align: right;
  }

  &.halfRow {
    background-size: 50% 100% !important; // stylelint-disable-line declaration-no-important
  }

  .fixedItemHeight {
    > div > div {
      height: 120px;
    }
  }

  &:not(.nested) {
    @include sectionStyle;
  }

  &.homeCareServices {
    padding: 0;
  }

  &.copyrightAndCredit {
    position: relative;

    .copyrightAndCredit {
      position: absolute;
      bottom: 18px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      color: #6e768a;

      svg path {
        fill: #6e768a;
      }
    }
  }

  &.withBackground {
    @include coverBackground;
    min-height: 645px;

    @include mobile {
      min-height: auto;
    }

    &:not(.dontTile) {
      @include lt-medium {
        background-image: none !important; // stylelint-disable-line declaration-no-important
        padding-bottom: 0;

        > div {
          padding-bottom: 50px;
        }
      }
    }
  }

  &.withBackgroundFixedHeight {
    background-size: auto 100%;
    background-position: top right;

    @include mobile {
      min-height: unset !important; // stylelint-disable-line declaration-no-important
    }
  }

  &.childrenAsSlider {
    @include mobile {
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax($slideItemWidth, $slideItemWidth));
      overflow-x: auto;
      grid-auto-flow: column;
      scroll-behavior: smooth;
      display: grid;
      margin: 0 auto;
      grid-gap: 24px;

      // override column padding css
      > * {
        padding-left: 0 !important; // stylelint-disable-line declaration-no-important
        padding-right: 0 !important; // stylelint-disable-line declaration-no-important
        width: $slideItemWidth;
      }
    }
  }
}

.section {
  position: relative;
  padding: 0!important;
  margin: auto!important;
  overflow: hidden;

  .container {
    padding: 0;
    margin: 0;

    h1,h2,h3,.textMimicH2,.textMimicH3 {
      margin-bottom: 20px;
    }
  }

  a {
    color:inherit!important;
    text-decoration: underline;
    font-weight: bold;
    text-decoration-color: $orangeActive;
    text-underline-offset: 4px;
  }

  .row:not(.nested) {
    padding: 0!important;
    display: flex;
    flex-wrap: wrap;

    > div {
      position: relative;
      padding: 2rem;
      margin: 0;

      @media only screen and (min-width: 992px) {
        padding: 5rem 4rem;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .row :global(.col-12:nth-last-of-type(n+2)) {
      padding-bottom: 0;
    }

    .row :global(.col-12:not(:first-of-type)) {
      padding-top: 0;
    }
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.row {
  .columnContainer:first-of-type:nth-of-type(1):nth-last-of-type(2) {
    .imagePresent {
      @media only screen and (min-width: 992px) {
        margin-left: -4rem;
      }
    }
  }

  .columnContainer:last-of-type:nth-of-type(2) {
    .imagePresent {
      @media only screen and (min-width: 992px) {
        margin-left: 0.9rem;
      }
    }
  }
}


.columnBackgroundPosition {
  &.imagePresent {
    background-color: transparent!important;
    position: relative!important;
    min-height: 400px;
    margin-left: -3rem;
    margin-bottom: -2rem;
    margin-top: 2rem;
    width: calc(100% + 6rem);

    @media only screen and (min-width: 992px) {
      position: absolute!important;
      margin-left: 0;
      margin-bottom: 0;
      margin-top: -5rem;
      width: calc(100% + 3.2rem);
      height: calc(100% + 10rem);
    }

    .svgOverlay {
      display: none!important;
    }
    .whiteUnderlay {
      display: none!important;
    }
    .backgroundImage {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}

.columnBackgroundPosition {
  z-index: -1;
}

.rowBackgroundPosition {
  z-index: -2;
}

.rowBackgroundPosition,
.columnBackgroundPosition {
  position: absolute!important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  max-width: unset!important;

  .svgOverlay {
    fill: transparent;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: .4;
  }

  .whiteUnderlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    opacity: 1;
    background: white;
  }
}

.section {
  margin-bottom: 1rem!important;

  &:global(.noBottomMargin) {
    margin-bottom: 0!important;
  }
  &.bg-blue-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $blueDeep;
    }
    & > div > .svgOverlay {
      background-color: $blueActive;
    }
  }
  &.bg-orange-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    & > div > .svgOverlay {
      background-color: $orangeActive;
    }
  }
  &.bg-stone-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $stoneDeep;
    }
    & > div > .svgOverlay {
      background-color: $stoneActive;
    }
  }
  &.bg-teal-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $tealDeep;
    }
    & > div > .svgOverlay {
      background-color: $tealActive;
    }
  }
  &.bg-transparent {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    & > div > .svgOverlay {
      background-color: transparent;
    }
    .whiteUnderlay {
      display: none;
    }
  }
  &.bg-white {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    & > div > .svgOverlay {
      background-color: $whitePlain;
    }
  }

  &.bg-blue-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $blueDeep;
    }
    & > div > .svgOverlay {
      background-image: radial-gradient(circle, rgba(92,139,185,.1) 0%, rgba(92,139,185,1) 80%);
      fill: $blueActive;
    }
  }
  &.bg-orange-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    & > div > .svgOverlay {
      background-image: radial-gradient(circle, rgba(248,124,93,.1) 0%, rgba(248,124,93,1) 80%);
      fill: $orangeActive;
    }
  }
  &.bg-stone-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $stoneDeep;
    }
    & > div > .svgOverlay {
      background-image: radial-gradient(circle, rgba(214,210,196,.1) 0%, rgba(214,210,196,1) 80%);
      fill: $stoneActive;
    }
  }
  &.bg-teal-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $tealDeep;
    }
    & > div > .svgOverlay {
      background-image: radial-gradient(circle, rgba(99,177,188,.1) 0%, rgba(99,177,188,1) 80%);
      fill: $tealActive;
    }
  }
}

.columnContainer {
  &.bg-blue-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $blueDeep;
    }
    .svgOverlay {
      background-color: $blueActive;
    }
  }
  &.bg-orange-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    .svgOverlay {
      background-color: $orangeActive;
    }
  }
  &.bg-stone-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $stoneDeep;
    }
    .svgOverlay {
      background-color: $stoneActive;
    }
  }
  &.bg-teal-active {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $tealDeep;
    }
    .svgOverlay {
      background-color: $tealActive;
    }
  }
  &.bg-transparent {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    .svgOverlay {
      background-color: transparent;
    }
    .whiteUnderlay {
      display: none;
    }
  }
  &.bg-white {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    .svgOverlay {
      background-color: $whitePlain;
    }
  }

  &.bg-blue-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $blueDeep;
    }
    .svgOverlay {
      background-image: radial-gradient(circle, rgba(92,139,185,.1) 0%, rgba(92,139,185,1) 80%);
      fill: $blueActive;
    }
  }
  &.bg-orange-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $orangeDeep;
    }
    .svgOverlay {
      background-image: radial-gradient(circle, rgba(248,124,93,.1) 0%, rgba(248,124,93,1) 80%);
      fill: $orangeActive;
    }
  }
  &.bg-stone-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $stoneDeep;
    }
    .svgOverlay {
      background-image: radial-gradient(circle, rgba(214,210,196,.1) 0%, rgba(214,210,196,1) 80%);
      fill: $stoneActive;
    }
  }
  &.bg-teal-signature {
    h1,h2,h3,.textMimicH2,.textMimicH3 {
      color: $tealDeep;
    }
    .svgOverlay {
      background-image: radial-gradient(circle, rgba(99,177,188,.1) 0%, rgba(99,177,188,1) 80%);
      fill: $tealActive;
    }
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
